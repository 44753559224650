var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"main-content"},[_c('div',{staticClass:"tabs"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'store.product-info' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'is-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("General")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'store.product-options' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'is-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Opciones")])])]}}])})],1)]),(_vm.reloadView)?_c('router-view'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }