<template>
  <div>
    <store-header
      :selectedStore="selectedStore"
      :client="username"
      :total="totalProductsCart"
    />
    <transition name="fadeRight">
      <cart-preview
        v-if="showPreview"
        :selectedStore="selectedStore"
        :itemAdded="itemAdded"
        :client="username"
      />
    </transition>

    <div>
      <div class="store" v-if="storeAllowed">
        <div v-if="selectedStore">
          <div class="shop-detail__header flex flex-column">
            <div class="shop-detail__header-logo">
              <img
                :src="profilePic"
                class="shop-detail__header-image"
                alt="store-img"
              />
            </div>
            <div
              class="
                shop-detail__header-description
                shop-description shop-description-sm
              "
            >
              <div class="header-sm">
                <div class="shop-detail__header-title">
                  <div class="shop-detail__header-name">
                    {{ selectedStore.nombre }}
                  </div>
                  <div class="shop-detail__header-id text-align-xs">
                    {{ selectedStore["name-id-canonical"] }}
                  </div>
                </div>
                <div class="header-sm">
                  <div class="shop-detail__header-category">
                    {{
                      selectedStore.categoria
                        ? selectedStore.categoria.value
                        : "-"
                    }}
                  </div>
                  <div class="shop-detail__header-about text-align-xs">
                    {{ selectedStore.descripcion }}
                  </div>
                  <div class="shop-detail__header-address text-align-xs">
                    &#128204;
                    {{ selectedStore.direccion }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid-x grid-padding-x medium-unstack"
          v-if="products.length > 0"
        >
          <item-product
            v-for="product in products"
            :key="product.id"
            :data="product"
            :disabled="!hasStock(product)"
            class="flex-item flex-item-md flex-item-sm"
          >
            <template v-slot:footer>
              <hcc-button
                variant="outline"
                size="sm"
                v-if="hasStock(product)"
                @click="addToCar(product)"
              >
                {{ $t("shop.add") }}
              </hcc-button>
              <div v-else class="shop-detail__badge">
                <hcc-badge
                  :text="
                    product.categoria.id === 'Producto'
                      ? $t('shop.out-of-stock')
                      : $t('shop.not-available')
                  "
                  color="muted"
                  size="lg"
                  fill
                />
              </div>
            </template>
          </item-product>
        </div>
        <div
          :class="{ open: forceShowCar }"
          style="animation-duration: 1.5s"
          v-if="hasItemsCar"
        ></div>
      </div>
      <div v-else><h2 class="text-center">{{ $t('shop.product.disabled-qr') }}</h2></div>
      <div v-if="message">{{ message }}</div>
    </div>
    <hcc-modal
      :title="$t('shop.product.add-comment')"
      class="shop"
      :width="400"
      name="shop-product-comment"
      @confirm="confirmAddProduct"
      @cancel="cancelAddProduct"
      :adaptive="true"
    >
      <div>
        <hcc-input
          name="product-comment"
          class="store__cart-add-product__comment"
          :placeholder="$t('shop.product.comment')"
          v-model="currentProduct.comment"
          :label="$t('shop.product.comment')"
          ref="hccInput"
        />
        <option-list
          :groupOptions="groupOptions"
          @onGetOption="getOptions"
        />
      </div>
    </hcc-modal>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapState, mapActions } from 'vuex';
import ItemProduct from '@/components/shop/ItemProduct.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccBadge from '@/components/shared/HccBadge/index.vue';
import HccModal from '@/components/shared/HccModal/index.vue';
import OptionList from '@/components/shop/OptionList.vue';
import StoreHeader from './StoreHeader.vue';
import CartPreview from './CartPreview.vue';


export default {
  components: {
    ItemProduct,
    HccButton,
    HccBadge,
    StoreHeader,
    CartPreview,
    HccModal,
    HccInput,
    OptionList,
  },
  data: () => ({
    message: '',
    forceShowCar: false,
    username: null,
    itemAdded: null,
    showPreview: false,
    timer: null,
    currentProduct: {},
    groups: [],
  }),
  async mounted() {
    await this.getSelectedStore(this.$route.params.id);
    this.username = this.$route.params.client;
    if (this.isFromQR && this.selectedStore) {
      await this.findOrCreateSession(this.selectedStore.id);
      this.username = this.session;
    } else {
      await this.removeOldSessions();
    }

    await this.getCar({
      user: this.username,
      storeId: this.$route.params.id,
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.showPreview = false;
    this.itemAdded = null;
  },
  computed: {
    ...mapState({
      selectedStore: state => state.shop.item,
      products: state => state.product.items,
      currentCar: state => state.shop.currentCar,
      session: state => state.shop.session,
      groupOptions: state => state.product.groupOptionsItems,
    }),
    profilePic() {
      if (this.selectedStore.imageURL) {
        return this.selectedStore.imageURL;
      }

      return 'https://cdn2.iconfinder.com/data/icons/facebook-ui-colored/48/JD-15-512.png';
    },
    hasItemsCar() {
      return this.car.length > 0;
    },
    car() {
      return this.currentCar ? (this.currentCar.car || []) : [];
    },
    totalProductsCart() {
      return this.car.reduce((total, product) => total + product.quantity, 0);
    },
    storeAllowed() {
      if (this.isFromQR) {
        return this.selectedStore.settings.showQR;
      }
      return true;
    },
    isFromQR() {
      return this.$route.params.client === 'qr-code';
    },
  },
  methods: {
    ...mapActions({
      getCar: 'shop/leadPendingCar',
      getSelectedStore: 'shop/getById',
      storeLead: 'shop/newOrUpdateLead',
      getProducts: 'product/getActiveItems',
      removeOldSessions: 'shop/removeSession',
      findOrCreateSession: 'shop/findSession',
      getGroupOption: 'product/getGroupOption',
    }),
    getOptions(groups) {
      this.groups = groups;
    },
    async addToCar(product) {
      const tempCar = [...this.car];
      const indexProduct = tempCar.findIndex(({ id }) => id === product.id);
      if (typeof product.stock !== 'undefined') {
        if (indexProduct !== -1
          && tempCar[indexProduct].quantity >= product.stock
        ) {
          this.$toasted.global.error(this.$t('shop.stock-warning'));
          return;
        }
      }
      if (indexProduct !== -1) {
        tempCar[indexProduct].quantity += 1;
        this.productAdded(product, tempCar);
      } else {
        this.$modal.show('shop-product-comment');
        this.currentProduct = { ...product };
        await this.getGroupOption(this.currentProduct.id);
      }
    },
    confirmAddProduct() {
      const tempCar = [...this.car];
      tempCar.push({
        id: this.currentProduct.id,
        nombre: this.currentProduct.nombre,
        compra: numeral(this.currentProduct.precioCompra).value(),
        precio: numeral(this.currentProduct.precio).value(),
        quantity: 1,
        comment: this.currentProduct.comment || '',
        groups: this.groups,
      });
      this.productAdded(this.currentProduct, tempCar);
    },
    productAdded(product, tempCar) {
      this.itemAdded = {
        nombre: product.nombre,
        precio: product.precio,
        imageURL: product.imageURL,
      };
      this.showPreviewAlert();
      this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: tempCar,
      });
    },
    cancelAddProduct() {
      this.currentProduct = {};
    },
    showPreviewAlert() {
      this.showPreview = true;

      this.timer = setInterval(() => {
        this.showPreview = false;
      }, 5000);
    },
    hasStock({ stock, status }) {
      if (typeof stock !== 'undefined') {
        return stock > 0;
      }

      return status;
    },
  },
  watch: {
    selectedStore(newValue) {
      if (!newValue) {
        this.message = this.$t('shop.non-exist');
      } else {
        this.getProducts(newValue.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/_store.scss";
</style>
