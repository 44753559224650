<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <hcc-table
      :initialPage="1"
      :columns="columns"
      :rows="groups"
      :group-options="{ enabled: true, collapsable: true }"
      @edit="edit"
      @add="openAddGroup"
      @delete="deleteOption"
      @addSubgroup="addSubCategory"
      :pagination="false"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'activo'">
          <div class="badget">
            {{ $t(props.row.activo ? 'shop.product.active' : 'shop.product.inactive') }}
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="group-options-modal"
        :title="$t('shop.product.options.title-group-form')"
        @confirm="createNewGroup"
        @cancel="cancel"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input cell"
            v-focuseModal
            :label="$t('common.name')"
            v-model.trim="newGroup.nombre"
          />
          <hcc-select
            :label="$t('shop.product.category')"
            class="cell"
            custom-class="select"
            v-model="newGroup.tipo"
            :placeholder="$t('shop.product.select-option')"
            :options="selections"
            :error="$v.newGroup.tipo.$error"
            :errorMessage="$t('shop.errors.required', { field: $t('shop.product.category') })"
            option-label="value"
            track-by="id"
          />

        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <ModalFormOptions :option="newOption" @save="createOrUpdateOption" />
    </transition>
    <transition name="fade">
      <error-modal :message="errorMessage" @error="handleErrorConfirm" />
    </transition>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    OverlayLoader,
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    ModalFormOptions: () => import('@/components/shop/options/ModalFormOptions.vue'),
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  data() {
    return {
      loading: true,
      options: {},
      newGroup: {
        opciones: [],
      },
      newOption: {},
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      groupOptions: state => state.product.groupOptionsItems,
    }),
    columns() {
      return [
        {
          label: this.$t('shop.product.options.name'),
          field: 'name',
        }, {
          label: this.$t('shop.product.options.extra-cost'),
          field: 'costoExtra',
        }, {
          label: this.$t('shop.product.options.purchase'),
          field: 'compra',
        }, {
          label: this.$t('shop.product.options.inventory'),
          field: 'inventario',
        }, {
          label: this.$t('shop.product.options.active'),
          field: 'activo',
        },
      ];
    },
    selections() {
      return [
        { id: 'Unica', value: this.$t('shop.product.options.unique-option') },
        { id: 'Multiple', value: this.$t('shop.product.options.multiple-choice') },
      ];
    },
    idProduct() {
      return this.$route.params.idProduct;
    },
    groups() {
      return this.groupOptions.map((item, index) => ({
        ...item,
        index,
        name: `${item.nombre} - ${item.tipo?.value}`,
        children: (item.opciones || []).map((option, indexSub) => ({
          ...option,
          id: indexSub,
          parent: item.id,
          editable: false,
          name: option.lista,
          title: option.lista,
        })),
      }));
    },
  },
  validations: {
    newGroup: {
      nombre: { required },
      tipo: { required },
    },
  },
  methods: {
    ...mapActions({
      getGroupOption: 'product/getGroupOption',
      removeGroup: 'product/removeGroup',
      saveGroupOptions: 'product/saveGroupOptions',
    }),
    edit(row) {
      if (row.parent) {
        this.editSubCategory(row);
      } else {
        this.newGroup = {
          id: row.id,
          tipo: row.tipo,
          nombre: row.name,
        };
        this.$modal.show('group-options-modal');
      }
    },
    deleteOption(row) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.remove-product'),
        description: this.$t('shop.product.confirm-remove-product'),
        variant: 'default',
        confirm: () => this.removeGroupOrOption(row),
      });
    },
    openAddGroup() {
      this.newGroup = { opciones: [] };
      this.$modal.show('group-options-modal');
    },
    addSubCategory(row) {
      this.newOption = {
        parent: row.id,
      };
      this.$modal.show('product-form-options');
    },
    editSubCategory(row) {
      this.newOption = { ...row };
      this.$modal.show('product-form-options');
    },
    async createNewGroup() {
      this.loading = true;
      await this.saveGroupOptions({
        id: this.idProduct,
        newGroup: { ...this.newGroup },
      });
      this.loading = false;
    },
    async createOrUpdateOption(data) {
      this.loading = true;
      const {
        id: optionIndex,
        lista,
        parent,
        compra,
        activo,
        costoExtra,
        inventario,
      } = data;
      const { opciones = [] } = this.groupOptions.find(({ id }) => id === parent);
      const newOption = {
        inventario,
        compra,
        activo,
        costoExtra,
        lista,
      };

      if (optionIndex >= 0) {
        opciones.splice(optionIndex, 1, newOption);
      } else {
        opciones.push(newOption);
      }

      await this.saveGroupOptions({
        id: this.idProduct,
        newGroup: {
          id: parent,
          opciones,
        },
      });
      this.loading = false;
    },
    cancel() {
      this.$modal.hide('product-form-options');
      this.$modal.hide('group-options-modal');
    },
    handleErrorConfirm() {

    },
    async removeGroupOrOption({ parent, id }) {
      this.loading = true;
      if (parent) {
        const { opciones = [] } = this.groupOptions.find(({ id: groupId }) => groupId === parent);
        opciones.splice(id, 1);
        await this.saveGroupOptions({
          id: this.idProduct,
          newGroup: {
            id: parent,
            opciones,
          },
        });
      } else {
        this.removeGroup({ id, idProducto: this.idProduct });
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getGroupOption(this.idProduct);
    this.loading = false;
  },
};
</script>

<style>
.multiselect.select {
  width: 100%;
}
</style>
