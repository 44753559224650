<template>
  <div class="store__preview">
    <div class="store__preview-added">{{ $t("shop.added-cart") }}</div>
    <item-product
      :data="itemAdded"
      class="store__preview-item"
      :hasPreview="false"
    />
    <router-link
      :to="{
        name: 'store-cart.admin.sellia',
        params: { id: selectedStore.id, client: client },
      }"
      class="store__header-router"
    >
      <hcc-button color="primary" class="store__preview-button" size="sm">
        {{ $t("shop.view-cart") }}</hcc-button
      >
    </router-link>
  </div>
</template>

<script>
import ItemProduct from '@/components/shop/ItemProduct.vue';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    ItemProduct, HccButton,
  },
  props: {
    selectedStore: {
      type: Object,
    },
    itemAdded: {
      type: Object,
    },
    client: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/_store.scss";
</style>
