<template>
  <div>
    <section class="main-content">
      <div class="tabs">
        <ul>
          <router-link
            :to="{ name: 'store.product-info' }"
            custom
            v-slot="{ href, navigate, isExactActive }"
          >
            <li
              :class="[isExactActive && 'is-active']">
              <a :href="href" @click="navigate">General</a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'store.product-options' }"
            custom
            v-slot="{ href, navigate, isExactActive }"
          >
            <li
              :class="[isExactActive && 'is-active']">
              <a :href="href" @click="navigate">Opciones</a>
            </li>
          </router-link>
        </ul>
      </div>

      <router-view v-if="reloadView" />
    </section>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  props: {
    idProduct: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groupData: {},
      component: 'group-options-table',
      reloadView: true,
    };
  },
  computed: {
    selectedIdGroup() {
      return this.groupData.idGroup;
    },
  },
  watch: {
    '$route.name': function watchRoute(to) {
      if (to === 'store.product-info') {
        this.reloadView = false;
        setTimeout(() => {
          this.reloadView = true;
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getById',
      getGroupOption: 'product/getGroupOption',
    }),
    change({ component, ...data }) {
      this.groupData = data;
      this.component = component;
    },
  },
  async mounted() {
    await this.getProductById(this.idProduct);
    await this.getGroupOption(this.idProduct);
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/_campaign-configuration.scss';
</style>
