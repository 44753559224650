<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <ProductForm
      :idProduct="idProduct"
      :product="product"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  components: {
    OverlayLoader,
    ProductForm: () => import('@/components/shop/ProductOptionsForm.vue'),
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({ product: state => state.product.item }),
    idProduct() {
      return this.$route.params.idProduct;
    },
  },
  methods: {
    ...mapActions({ getProductById: 'product/getById' }),
  },
  async mounted() {
    this.loading = true;
    await this.getProductById(this.idProduct);
    this.loading = false;
  },
  watch: {
    async idProduct(newValue) {
      await this.getProductById(newValue);
    },
    '$route.params.idProduct': async function idProduct() {
      this.loading = true;
      await this.getProductById(this.idProduct);
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_products-options.scss";
</style>
